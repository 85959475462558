import React, { useContext, useEffect, useState } from "react"
import { FeaturedCalloutProps } from "./FeaturedCallout.d"
import { Image } from "../../../atoms/Image"
import { ButtonLink } from "../../../atoms/Button"
import tw from "twin.macro"
import { getCTALinkValue, parseDisclaimerBlocks } from "../../../../helpers"
import { toggleDisclaimersModal } from "../../../../contexts/Disclaimers/actions"
import { DisclaimersContext } from "../../../../contexts/Disclaimers"

/**
 * @author Tyler
 * @summary - Callout featured on the homepage. Currently featuring ToyotaCare.
 *
 * @component
 * @param {ImageWithAlt} image - Desktop background image
 * @param {ImageWithAlt} mobileImage - Mobile background image
 * @param {ImageWithAlt} headingImage - Image above the heading in content
 * @param {string} heading - Heading in content
 * @param {string} body - Main body in content
 * @param {CTA} cta - Call to action at bottom of section
 *
 * @returns {JSX.Element} <FeaturedCallout />
 */

const FeaturedCallout = ({
  image,
  mobileImage,
  headingImage,
  heading,
  body,
  cta,
  sectionTitle,
  sectionSlug,
  margins,
}: FeaturedCalloutProps): JSX.Element => {
  const [desktopView, setDesktopView] = useState(null)
  const [state, dispatch] = useContext(DisclaimersContext)

  // Detect screen width in DOM to control which image to render in background
  useEffect(() => {
    const mediaQuery = window.matchMedia("(min-width: 640px)")
    setDesktopView(mediaQuery.matches || false)
    mediaQuery.addEventListener("change", e => {
      const mobileViewOn = e.matches
      setDesktopView(mobileViewOn)
    })
    return () => {
      mediaQuery.removeEventListener("change", e => {
        const mobileViewOn = e.matches
        setDesktopView(mobileViewOn)
      })
    }
  }, [])

  return (
    // DO NOT REFACTOR TO USE COL-SPAN OR ROW-SPAN, IT DOES NOT WORK WHEN OVERLAPPING ELEMENTS
    <section
      css={[
        tw`h-[850px] relative`,
        tw`md:(h-[700px])`,
        tw`2xl:(h-[775px])`,
        `margin: ${margins?.top}px 0 ${margins?.bottom}px !important;`,
      ]}
      id={sectionSlug?.current}
      aria-label="Featured Section"
    >
      {/* BACKGROUND */}
      {desktopView ? (
        // Desktop background image
        <Image
          imageData={image.image}
          css={[tw`w-full h-full z-0 absolute object-cover`]}
        />
      ) : (
        // Mobile background image
        <Image
          imageData={mobileImage.image}
          css={tw`w-full h-full z-0 absolute object-cover`}
        />
      )}
      {/* CONTENT */}
      <div
        css={[
          tw`h-full grid grid-cols-1 auto-rows-min content-between pt-20`,
          tw`sm:(pt-12)`,
          tw`md:(pt-20)`,
        ]}
      >
        <article
          css={[
            tw`col-start-1 col-end-2 row-start-1 row-end-2 px-8 self-center z-10`,
            tw`md:(flex justify-center gap-14)`,
          ]}
        >
          {/* Heading image */}
          {headingImage && (
            <div css={[tw`my-auto`]}>
              <Image imageData={headingImage.image} alt={headingImage?.alt} />
            </div>
          )}
          {/* Heading and body */}
          <div css={[tw`max-w-md w-full`]}>
            {heading && (
              <h2
                css={[
                  tw`text-3xl font-light pt-14 pb-3`,
                  "letter-spacing: 7.26px;",
                  tw`md:(pt-0)`,
                ]}
              >
                {parseDisclaimerBlocks(heading, selection =>
                  dispatch(toggleDisclaimersModal(selection))
                )}
              </h2>
            )}
            {body && <p css={[tw`text-xl`, "line-height: 1.5rem;"]}>{body}</p>}
          </div>
        </article>
        {/* Call to action */}
        {cta && (
          <div
            css={[
              tw`col-start-1 col-end-2 row-start-2 row-end-3 self-end justify-self-center pb-12`,
            ]}
          >
            <ButtonLink
              {...{ [cta?.buttonType]: true }}
              to={getCTALinkValue(cta)}
              target={cta?.linkType === "external" ? "_blank" : "_self"}
              aria-label={cta?.title}
              css={[tw`w-60`]}
            >
              {cta?.title}
            </ButtonLink>
          </div>
        )}
      </div>
    </section>
  )
}

export default FeaturedCallout
